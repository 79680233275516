@media only screen and (max-width: 295px) {
  #ent {
    display: none;
  }
}

@media only screen and (max-width: 420px) {
  #clinic {
    display: none;
  }
}
