body {
  margin: 0;
  font-family: "微軟正黑體", Noto Sans TC, -apple-system, BlinkMacSystemFont,
    Microsoft JhengHei, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #005742;
  font-size: calc(16px + (26 - 14) * ((100vw - 150px) / (1600 - 300)));
  line-height: 2;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html body {
  background: rgba(255, 237, 237, 1);
  background-image: linear-gradient(
    89.9deg,
    rgba(208, 246, 255, 1) 0.1%,
    rgba(255, 237, 237, 1) 47.9%,
    rgba(255, 255, 231, 1) 100.2%
  );
}

.color {
  /* background: rgb(196, 255, 241); */
  /* background-image: linear-gradient(
    95.2deg,
    rgb(196, 255, 241) 26.8%,
    rgb(213, 242, 255) 64%
  ); */
  background: rgba(255, 237, 237, 1);
  background-image: linear-gradient(
    89.9deg,
    rgba(208, 246, 255, 1) 0.1%,
    rgba(255, 237, 237, 1) 47.9%,
    rgba(255, 255, 231, 1) 100.2%
  );
}

::selection {
  background: #005742;
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiDrawer-paper {
  width: 250px;
  max-width: 75vw;
}

p {
  margin: 5;
}